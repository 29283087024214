<script>
import { h, ref } from 'vue'
import bannerGroupTemplate from './components/banner-group-template.vue'
import { ElTabs, ElTabPane } from 'element-plus'
export default {
  setup () {
    const bannerGroupTemplateCom = ref(null)
    const tabs = {
      activeName: 'bannerGroupTemplate',
      tabs: [
        { label: '宣传栏组合模板', name: 'bannerGroupTemplate', component: bannerGroupTemplate, ref: bannerGroupTemplateCom }
      ]
    }
    return {
      tabs
    }
  },
  render () {
    return h(
      ElTabs, {
        modelValue: this.tabs.activeName
      }, () => this.tabs.tabs.map(item => {
        return h(ElTabPane, {
          name: item.name,
          label: item.label
        }, () => [
          h(item.component, {
            ref: item.ref || ''
          })
        ])
      })
    )
  }
}
</script>
<style lang="scss" scoped>
</style>
