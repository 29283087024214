<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-button size="small" type="primary" @click="add">新增模板</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`宣传栏组合列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="typeName" label="类型"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="desc" label="说明"></el-table-column>
        <el-table-column label="封面图" width="92">
          <template #default="scope">
            <el-image style="width: 70px; height: 70px" :src="scope.row.image" fit="contain" :preview-src-list="[scope.row.image]" :initial-index="1"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="minCount" label="最小数量" width="80"></el-table-column>
        <el-table-column prop="maxCount" label="最大数量" width="80"></el-table-column>
        <el-table-column label="状态" width="70">
          <template #default="scope">
            <el-tag type="primary" size="small" v-if="scope.row.flag === 1">显示</el-tag>
            <el-tag type="info" size="small" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="setFlag(scope.row.id, scope.row.flag)">{{scope.row.flag===1?'隐藏':'显示'}}</el-button>
            <el-dropdown @command="handleCommand">
              <el-button type="text" size="small" class="el-dropdown-link">更多</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'up'}" v-if="!scope.row._isFirst">上移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'down'}" v-if="!scope.row._isLast">下移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'top'}" v-if="!scope.row._isFirst">置顶</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'bottom'}" v-if="!scope.row._isLast">置底</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'clear', id: scope.row.id}">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <!-- 类型 -->
      <el-form-item label="类型" prop="type" :rules="[
        {required:true, message: '类型不能为空', trigger: 'blur'}
      ]">
        <el-select v-model="dialog.data.type" placeholder="请选择类型" size="small" clearable>
          <el-option key="0" label="轮播图（横条大）" value="0"></el-option>
          <el-option key="7" label="轮播图（横条中）" value="7"></el-option>
          <el-option key="1" label="轮播图（横条小）" value="1"></el-option>
          <el-option key="8" label="轮播图（方形）" value="8"></el-option>
          <el-option key="9" label="轮播图（竖条）" value="9"></el-option>
          <el-option key="12" label="轮播图（全屏）" value="12"></el-option>
          <el-option key="2" label="方块（2列）" value="2"></el-option>
          <el-option key="3" label="方块（3列）" value="3"></el-option>
          <el-option key="4" label="方块组合" value="4"></el-option>
          <el-option key="5" label="圆角图标" value="5"></el-option>
          <el-option key="6" label="方形图标" value="6"></el-option>
        </el-select>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" maxlength="16" clearable></el-input>
      </el-form-item>
      <!-- 说明 -->
      <el-form-item label="说明" prop="desc" :rules="[
        {required:true, message: '说明不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.desc" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
      <!-- 最小数量 -->
      <el-form-item label="最小数量" prop="minCount" :rules="[
        {required:true, message: '最小数量不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.minCount" size="small" type="number" clearable></el-input>
      </el-form-item>
      <!-- 最大数量 -->
      <el-form-item label="最大数量" prop="maxCount" :rules="[
        {required:true, message: '最大数量不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.maxCount" size="small" type="number" clearable></el-input>
      </el-form-item>
      <!-- 封面图片 -->
      <el-form-item label="封面图" prop="image" :rules="[
        {required: !dialog.data.image, message: '封面图不能为空', trigger: 'blur'}
      ]">
        <el-upload action="" :http-request="uploadImage" :on-exceed="exceedImage" :limit="1" list-type="picture">
          <el-button size="small" type="default">上传图片</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag, ElUpload, ElImage } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
import upload from '@/common/utils/upload'
import type from '@/common/utils/type'

function table () {
  /**
   * 表单信息
   */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/banner/group/template/list', {
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.descGroupTemplateList, res.totalCount)
          resolve({
            list: res.bannerGroupTemplateList.map(bannerGroupTemplate => {
              switch (bannerGroupTemplate.type) {
                case type().bannerGroupTemplateType.carouselBig:
                  bannerGroupTemplate.typeName = '轮播图（横条大）'
                  break
                case type().bannerGroupTemplateType.carouselMiddle:
                  bannerGroupTemplate.typeName = '轮播图（横条中）'
                  break
                case type().bannerGroupTemplateType.carouselSmall:
                  bannerGroupTemplate.typeName = '轮播图（横条小）'
                  break
                case type().bannerGroupTemplateType.twoSquare:
                  bannerGroupTemplate.typeName = '方块（2列）'
                  break
                case type().bannerGroupTemplateType.threeSquare:
                  bannerGroupTemplate.typeName = '方块（3列）'
                  break
                case type().bannerGroupTemplateType.combineSquare:
                  bannerGroupTemplate.typeName = '方块组合'
                  break
                case type().bannerGroupTemplateType.roundIcon:
                  bannerGroupTemplate.typeName = '圆形图标'
                  break
                case type().bannerGroupTemplateType.squareIcon:
                  bannerGroupTemplate.typeName = '方形图标'
                  break
                case type().bannerGroupTemplateType.carouselSquare:
                  bannerGroupTemplate.typeName = '轮播图（方形）'
                  break
                case type().bannerGroupTemplateType.carouselVertical:
                  bannerGroupTemplate.typeName = '轮播图（竖条）'
                  break
                case type().bannerGroupTemplateType.carouselFull:
                  bannerGroupTemplate.typeName = '轮播图（全屏）'
                  break
                default:
                  break
              }
              return bannerGroupTemplate
            }),
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }
  onMounted(async () => {
    table.value.reload()
  })

  return {
    table,
    queryData,
    totalCount
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function operation (t, d, dr) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板'
    d.dialog.data = {
      bannerGroupTemplateId: '',
      typ: '',
      name: '',
      desc: '',
      minCount: '',
      maxCount: '',
      image: ''
    }
  }
  /**
     * 修改
     */
  const modify = (row) => {
    d.dialog.visible = true
    d.dialog.title = '修改模板'
    d.dialog.data = {
      bannerGroupTemplateId: row.id,
      type: row.type.toString(),
      name: row.name,
      desc: row.desc,
      minCount: row.minCount,
      maxCount: row.maxCount,
      image: row.image
    }
  }
  /**
   * 保存图文模板
   */
  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }
  const submit = () => {
    axios('/manager/banner/group/template/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  /**
     * 编辑模板
     */
  const edit = async (id) => {
    dr.drawer.id = id
    dr.drawer.visible = true
  }
  /**
   * 更改状态
   */
  const setFlag = (id, flag) => {
    axios('/manager/banner/group/template/flag/set', {
      bannerGroupTemplateId: id,
      flag: flag === 1 ? 0 : 1
    }).then(res => {
      window.$message({
        message: '操作成功',
        type: 'success'
      })
      t.table.value.reloadCurrent()
    })
  }
  /**
     * 指令列表
     */
  const handleCommand = (command) => {
    commandList[command.name](command.id, command.action)
  }
  const commandList = {
    clear: (id) => { // 删除
      window.$confirm('是否确认删除').then(() => {
        del(id)
      }).catch(_ => { })
    },
    move: (id, action) => {
      axios('/manager/banner/group/template/move', {
        bannerGroupTemplateId: id,
        move: action
      }).then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reload()
      })
    }
  }
  const del = (id) => {
    axios('/manager/banner/group/template/del', {
      bannerGroupTemplateId: id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  /**
   * 上传图片
   */
  const uploadImage = ({ file }) => {
    upload(file, '0', (url) => {
      d.dialog.data.image = url
    })
  }
  /**
   * 超出数量提示
   */
  const exceedImage = () => {
    window.$message({
      message: '图片超出数量，请删除后上传',
      type: 'error'
    })
  }
  return {
    add,
    modify,
    confirm,
    edit,
    setFlag,
    handleCommand,
    uploadImage,
    exceedImage
  }
}

export default {
  components: { ElButton, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag, ElUpload, ElImage },

  setup () {
    const t = table()
    const d = dialog()
    const o = operation(t, d)
    return {
      ...t,
      ...d,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
:deep .tox-tinymce{
  height: calc(100% - 20px) !important;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
</style>
